import { MouseEventHandler } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetCampaignCategories } from '../../api/campaign/use-get-campaign-categories';
import { onlyUnique } from '../../utils/only-unique';
import { Checkbox } from '../library/checkbox';
import { LoadingBoundary } from '../library/loading-boundary';
import { UpdateFormCategoriesFn } from './campaign-form';
import { CampaignFormGroup } from './campaign-form-group';

interface CampaignCategoriesProps {
  updateFormCategories: UpdateFormCategoriesFn;
  value?: string[];
}

export const CampaignCategories = ({ updateFormCategories, value = [] }: CampaignCategoriesProps) => {
  const { t } = useTranslation();
  const { data: campaignCategories, isLoading, isError, error } = useGetCampaignCategories({ excludeDisabled: true });

  const handleSubcategoryChange = (subcategoryId: number, checked: boolean, parentCategoryId: number) => {
    let newCategories: string[] = [];

    if (checked) {
      // unselect subcategory
      newCategories = value.filter((value) => value != `${subcategoryId}`);
      // if all children are unselected, unselect parent category
      const subcategories = campaignCategories?.filter((data) => data.internalId === parentCategoryId) ?? [];
      const selectedSubcategories = subcategories.filter((subcategory) =>
        newCategories.includes(`${subcategory.internalId}`),
      );
      if (selectedSubcategories.length === 0) {
        // unselect parent category
        newCategories = newCategories.filter((value) => value != `${parentCategoryId}`);
      }
    } else {
      // toggle parent category
      newCategories = [...value, `${subcategoryId}`];
    }

    updateFormCategories({ name: 'categories', value: newCategories });
  };

  const handleCategoryChange = (categoryId: number, checked: boolean) => {
    let newCategories: string[] = [];
    const subcategoryIds: string[] =
      campaignCategories
        ?.find((data) => data.internalId == categoryId)
        ?.children.map((category) => `${category.internalId}`) ?? [];

    if (checked) {
      // unselect category and all subcategories
      newCategories = value.filter(
        (existingCategoryId) => existingCategoryId !== `${categoryId}` && !subcategoryIds.includes(existingCategoryId),
      );
    } else {
      // select category and all subcategories
      newCategories = [...value, `${categoryId}`, ...subcategoryIds];
    }

    updateFormCategories({ name: 'categories', value: newCategories.filter(onlyUnique) });
  };

  return (
    <CampaignFormGroup title={'Bereich'} controlId={'campaignCategories'} lastElement={true} required={true}>
      <Accordion className={'mb-8'}>
        <LoadingBoundary isLoading={isLoading} error={error} isError={isError}>
          <>
            {campaignCategories?.map((category) => {
              const checked =
                // category itself is selected
                value?.includes(`${category.internalId}`) ||
                // one of the categories children is selected
                !!category.children.find((category) => value?.includes(`${category.internalId}`));

              return (
                <Accordion.Item eventKey={`${category.internalId}`} key={`campaign-category-${category.internalId}`}>
                  <Accordion.Header>
                    <Checkbox
                      label={t(`campaignCategory.${category.slug}`)}
                      value={category.internalId}
                      checked={checked}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCategoryChange(category.internalId, checked);
                      }}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Group controlId={`category-group-${category.internalId}`}>
                      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-5">
                        {category.children.map((subcategory) => {
                          const checked = !!value?.find((value) => value == `${subcategory.internalId}`);

                          const onClick: MouseEventHandler = (e) => {
                            e.preventDefault();
                            handleSubcategoryChange(subcategory.internalId, checked, category.internalId);
                          };

                          return (
                            <Checkbox
                              key={`campaign-${category.internalId}-subcategory-${subcategory.internalId}`}
                              name="campaignCategory[]"
                              id={`subcategory-${subcategory.internalId}`}
                              label={t(`campaignCategory.${subcategory.slug}`)}
                              value={subcategory.internalId}
                              checked={checked}
                              onClick={onClick}
                            />
                          );
                        })}
                      </div>
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </>
        </LoadingBoundary>
      </Accordion>
    </CampaignFormGroup>
  );
};
