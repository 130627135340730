import { useQuery } from '@tanstack/react-query';
import { httpFetch } from '../../utils/http-fetch';
import { useAuth } from '../../utils/auth/use-auth';
import { CampaignCategory } from './type';

type Response = CampaignCategory[];

export const CAMPAIGN_CATEGORIES_QUERY_KEY = 'campaign-categories';

interface UseGetCampaignCategoriesParams {
  excludeDisabled: boolean;
}

export const useGetCampaignCategories = ({ excludeDisabled }: UseGetCampaignCategoriesParams) => {
  const { phpToken } = useAuth();

  const useGetCampaignCategories = async () => {
    const campaigns = await httpFetch<Response>(`/api/campaign-categories`, {
      phpToken,
    });

    return campaigns
      .filter(({ isDisabled }) => (excludeDisabled ? !isDisabled : true))
      .map(({ children, ...campaign }) => ({
        ...campaign,
        children: children.filter(({ isDisabled }) => (excludeDisabled ? !isDisabled : true)),
      }));
  };

  return useQuery<Response>([CAMPAIGN_CATEGORIES_QUERY_KEY, phpToken], useGetCampaignCategories);
};
